const prompts = [
  'honest politician',
  'angry maths',
  'the cat that got the cream',
  'egg on your face',
  'titanic made of cheese',
  'frog playing the saxophone',
  'posting a poo through someone’s letterbox',
  'shaking up a can of coke',
  'swim up bar',
  'creative accounting',
  'treasure Island',
  'going through airport security',
  'can’t see the woods for the trees',
  'guardian angel',
  'miracle moisturiser',
  'the second mouse gets the cheese',
  'the early bird gets the worm',
  'barking up the wrong tree',
  'nightmare bowlcut',
  'the light’s on but no one’s home',
  'afternoon tea with a meerkat',
  'bath full of baked beans',
  'curtain twitcher',
  'in bed with man flu',
  'mini golf on mars',
  'chickens abducted by aliens',
  'pinch punch first of the month',
  'kicking the bucket',
  'throwing a spanner in the works',
  'doing a runner from the restaurant',
  'board game cafe',
  'monopoly tantrum',
  'panic buying loo rolls',
  'where’s wally',
  'moaning myrtle',
  'mishap in the microbrewery',
  'raining cats and dogs',
  'square peg in a round hole',
  'throw down the gauntlet',
  'drain the swamp',
  'water under the bridge',
  'wild goose chase',
  'turn over a new leaf',
  'fixing a hole in your sock',
  'black Friday',
  'got the wrong end of the stick',
  'making a mountain out of a molehill',
  'fake it till you make it',
  'pie in the sky',
  'once bitten twice shy',
  'David Bowie taking a nap',
  'the tour de france is cancelled',
  'scoring an own goal',
  'earworm',
  'knight in shining armour',
  'take it on the chin',
  'camping with a baby',
  'teach an old dog new tricks',
  'want his head on a platter',
  'skating on thin ice',
  'going on safari',
  'eager beaver',
  'pain in the neck',
  'blowing smoke rings',
  'comparing apples with pears',
  'bury your head in the sand',
  'cat got your tongue',
  'bite the bullet',
  'walking on eggshells',
  'running around like a headless chicken',
  'the war of the worlds',
  'blow your own trumpet',
  'fall asleep during a movie',
  'brain like a sieve',
  'money doesn’t grow on trees',
  'look before you leap',
  'too many cooks spoil the broth',
  'grass is greener on the other side',
  'follow the yellow brick road',
  'hakuna Matata',
  'two wrongs don’t make a right',
  'pulling a sickie',
  'dead as a dodo',
  'supermarket sweep',
  'put a sock in it',
  'draw the short straw',
  'out on the town',
  'no room to swing a cat',
  'frothing at the mouth',
  'dropping like flies',
  'stealing honey from bees',
  'you are the weakest link, goodbye',
  'cheap as chips',
  'dalek dancing',
  'eat my shorts',
  'batman lost the batmobile',
  'up shit creek without a paddle',
  'an owl at a bus stop',
  'having a whale of a time',
  'basketcase',
  'drink like a fish',
  'a mouse doing karaoke',
  'going cold turkey',
  'a stopped clock is right twice a day',
  'stuck between a rock and a hard place',
  'having a chip on your shoulder',
  'jumping the gun',
  'shoot from the hip',
  'bison on a highway',
  'stealing someone’s thunder',
  'cut a long story short',
  'heard it on the grapevine',
  'smells like teen spirit',
  'the rolling stones',
  'cry me a river',
  'paradise city',
  'velvet underground',
  'the queen’s nose',
  'robot rap battle',
  'american idiot',
  'monkey gone to heaven',
  'tiny dancer',
  'personal jesus',
  'candle in the wind',
  'daft punk in the supermarket',
  'can’t buy me love',
  'lockdown haircut',
  'singing in the rain',
  'paranoid android',
  'rocket man',
  'walk on the wild side',
  'girls just wanna have fun',
  'bizarre love triangle',
  'crap wildlife photography',
  'knocking on heaven’s door',
  'the queen is dead',
  'bananas in pyjamas',
  'rock lobster',
  'while my guitar gently weeps',
  'carriage turns back into a pumpkin',
  'Donald Trump pumpkin carving',
  'born to be wild',
  'the house of the rising sun',
  'crazy in love',
  'tambourine man',
  'great balls of fire',
  'ring of fire',
  'anarchy in the UK',
  'king of the Jungle',
  'dancing in the street',
  'no woman no cry',
  'stairway to heaven',
  'blowing in the wind',
  'good vibrations',
  'put all your eggs in one basket',
  'flying elephant',
  'the massive mermaid',
  'angry dwarf',
  'buried treasure',
  'beat around the bush',
  'cutting corners',
  'rocket science',
  'needle in a haystack',
  'under the weather',
  'perfect storm',
  'add insult to injury',
  'break the ice',
  'every cloud has silver lining',
  'the butterfly effect',
  'out of the frying pan and into the fire',
  'embarrassed dog',
  'disco cat',
  'terrible holiday',
  'break a leg',
  'alligator school',
  'drunk dentist',
  'soggy sandwich',
  "donald Trump's brain",
  'stone age tennis',
  'chimp boxing',
  'penguin wrestling',
  'snail racing',
  'lost in London',
  'skateboarding on the moon',
  'pogo sticking in the arctic',
  'dancing underwater',
  'working 9 til 5',
  'gorilla warfare',
  'tallest animal contest',
  'poo at the end of the rainbow',
  'double chin',
  'an unfortunate tattoo',
  'russian doll',
  'luxury prison',
  'bottomless lunch',
  'T-rex ping pong',
  'fad diet',
  'magic pill',
  'pop up advert',
  'pet poker',
  'murder mystery',
  'horror movie hottie',
  'surprise sushi',
  'jack in a box',
  'ancient Egyptian pillow fight',
  'coronavirus love story',
  'awkward massage',
  'incompetent wizard',
  'unlikely priest',
  'vegetable growing contest',
  'second date',
  'tinder surprise',
  'minding the gap',
  'special hug',
  'awkward charity',
  'short sighted vet ',
  'confused firefighter',
  'dizzy pilot',
  'straight from the horse’s mouth',
  'painting oneself into the corner',
  'champagne socialist',
  'keeping friends close and enemies closer',
  'dreaming about toasting marshmallows',
  'easter egg hunt',
  'staring contest',
  'karaoke',
  'nuclear missile',
  'kangaroo boxing',
  'throwing stones',
  'sword fighting',
  'writing a letter',
  'shark attack',
  'ten pin bowling',
  'playing video games',
  'teddy bears picnic',
  'rock climbing',
  'windsurfing',
  'opera singer',
  'haunted house',
  'cutting down a tree',
  'reading a book',
  'zombie apocalypse',
  'sweeping the floor',
  'drawing the curtains',
  'corrupt Santa',
  'James and the giant peach',
  'Harry Potter twerking',
  'wheelbarrow full of cats',
  'lost jogger',
  'alien businessman',
  'kissing frogs',
  'golden bullet',
  'Sonic has a midlife crisis',
  'guardians of the galaxy',
  'third rare superhero',
  'hands free phone',
  'insect party',
  'made up relgion',
  'unlikely bicycle race',
  'Yeti president',
  'fairy godmother',
  'terrible singing',
  'over engineered circus trick',
  'portal to another world',
  'dangerous bridge',
  'hot air buffoon',
  'pop band made of cheese',
  'clown chess',
  'canine police line up',
  'treehouse',
  'terrible busker',
];
export const getPrompt = (): string => prompts[Math.floor(Math.random() * prompts.length)];
