import React, { FC } from 'react';
import { Box, BoxProps } from '@material-ui/core';

export const Row: FC<BoxProps> = (props) => (
  <Box flexDirection="row" display="flex" justifyContent="center" alignItems="center" {...props} />
);

export const Column: FC<BoxProps> = (props) => (
  <Box flexDirection="column" display="flex" justifyContent="center" alignItems="center" {...props} />
);
