import { createMuiTheme, Theme } from '@material-ui/core/styles';
import colors from './colors';

export interface ITheme extends Theme {
  custom?: {
    contentPadding: number;
    minimal: boolean;
  };
}

const extra = {
  custom: {
    contentPadding: 0,
    minimal: true,
  },
};

export const LightTheme: ITheme = createMuiTheme({
  ...extra,
  typography: {
    fontFamily: 'Comic Neue',
    fontSize: 16,
  },
  palette: {
    primary: {
      main: colors.primary,
    },
    secondary: {
      main: colors.secondary,
    },
    background: { default: '#fff' },
  },
  overrides: {
    MuiAppBar: {
      colorDefault: {
        backgroundColor: 'white',
      },
      root: {
        '&:after': {
          content: `''`,
          position: 'absolute' as 'absolute',
          zIndex: -1,
          width: '100%',
          height: '100%',
          opacity: 0,
          boxShadow:
            '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
          transition: 'opacity 0.3s ease-in-out',
        },
        '&.scrolled:after': {
          opacity: 1,
        },
        boxShadow: 'none',
      },
    },
    MuiInput: {
      root: {
        '&:read-only': { borderBottomColor: 'transparent' },
      },
    },
  },
});
