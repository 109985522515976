const tintColor = "#2f95dc";

export const theme = {
  black: "#191716",
  orange: "#FFA400",
  blue: "#5B5F97",
  red: "#D1603D",
  white: "#FFFBFC",
};

export default {
  primary: theme.orange,
  secondary: theme.blue,
  dark: "#263239",
  tintColor,
  tabIconDefault: "#ccc",
  tabIconSelected: tintColor,
  tabBar: "#fefefe",
  errorBackground: "red",
  errorText: "#fff",
  warningBackground: "#EAEB5E",
  warningText: "#666804",
  noticeBackground: tintColor,
  noticeText: "#fff",
};
