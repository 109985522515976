import React, { useContext } from 'react';
import playStore from '../stores/playStore';

export const StoreContext = React.createContext({});

export const useStoreContext = () => useContext(StoreContext);

export const StoreProvider = ({ children, ...stores }: any) => {
  return <StoreContext.Provider value={stores}>{children}</StoreContext.Provider>;
};

export function useStore(mapActions: any): playStore {
  const globalStore = useStoreContext() as any;
  let store;
  if (typeof mapActions === 'string') {
    store = globalStore[mapActions];
  } else {
    store = mapActions(globalStore);
  }
  if (typeof store === 'undefined') {
    const name = typeof store === 'string' ? store : mapActions;
    throw new Error("Store '" + name + "' is not available! Make sure it is passed on to the Store Provider");
  }
  return store;
}
