import React from 'react';
import { observer } from 'mobx-react-lite';
import { Typography } from '@material-ui/core';
import { useStore } from '../contexts/StoreContexts';
import { Column } from '../components/Boxes';
import { Player } from '../components/Player';

export const Waiting = observer(() => {
  const playStore = useStore('playStore');
  const { unSubmittedPlayers, submittedPlayers } = playStore;
  return (
    <Column>
      <Typography variant="h5">Waiting for all players to submit</Typography>

      <Typography variant="h6">Submitted:</Typography>

      {submittedPlayers?.map(Player)}

      <Typography variant="h6">Waiting:</Typography>

      {unSubmittedPlayers?.map(Player)}
    </Column>
  );
});
