import React from 'react';
import { Typography, Box } from '@material-ui/core';
import { Row } from '../components/Boxes';
import GeoPattern from 'geopattern';

export const Player = ({ name }: { name: string }) => {
  const pattern = GeoPattern.generate(name);
  return (
    <Row key={name} my={1}>
      <Box
        height={50}
        width={50}
        borderRadius="50%"
        mr={1}
        style={{ backgroundSize: 'contain', backgroundImage: pattern.toDataUrl() }}
      />
      <Typography variant="h6">{name}</Typography>
    </Row>
  );
};
