import { createClient, SupabaseClient } from '@supabase/supabase-js';
import { IgameData as IGameData, ISubmission } from '../interfaces';
import { playStore } from '../stores';
let supabase = null;

export const getSupabaseClient = (): SupabaseClient => {
  if (supabase) return supabase;

  const { REACT_APP_SUPABASE_KEY, REACT_APP_SUPABASE_URL } = process.env;

  supabase = createClient(REACT_APP_SUPABASE_URL, REACT_APP_SUPABASE_KEY);
  return supabase;
};

export const createGame = async (room: string, gameData: IGameData) => {
  const { data, error } = await getSupabaseClient().from('game').insert({ id: room, gameData }).single();

  if (error) {
    alert(error.message);
    console.error(error);
    return;
  }

  return data;
};

export const joinGame = async (room: string) => {
  const { data, error } = await getSupabaseClient().from('game').select('gameData').eq('id', room).single();

  if (error) {
    console.error(error);
    alert(error.message);
    return;
  }

  return data;
};

export const updateGameData = async (gameData: IGameData) => {
  const { data, error } = await getSupabaseClient().from('game').update({ gameData }).eq('id', gameData.room);

  if (error) {
    console.error(error);
    alert(error.message);
    return;
  }

  return data;
};

export const insertSubmission = async (submission: ISubmission) => {
  const { data, error } = await getSupabaseClient()
    .from('submissions')
    .insert([{ roomId: submission.room, submission }]);

  playStore.gameData.submissions.push(data[0].id);
  console.log(data[0].id);
  updateGameData(playStore.gameData);

  if (error) {
    console.error(error);
    alert(error.message);
    return;
  }

  return data;
};

export const subscribeToGameUpdates = async (room: string, onGameDataUpdate: (gameData: IGameData) => void) => {
  // TODO: Store reference somewhere so we can later unsubscribe (e.g. game end)
  getSupabaseClient()
    .from(`game:id=eq.${room}`)
    .on('UPDATE', (payload) => {
      onGameDataUpdate(payload.new.gameData as IGameData);
    })
    .subscribe();
};
