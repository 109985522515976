import React from 'react';
import { ThemeProvider, makeStyles } from '@material-ui/styles';
import { observer } from 'mobx-react-lite';
import { AppBar, Toolbar, CssBaseline, useMediaQuery, Typography, Box, Link, Button } from '@material-ui/core';
import Play from './screens/Play';
import { LightTheme } from './constants/themes';
import { StoreProvider, useStore } from './contexts/StoreContexts';
import logo from './canverse-small.png';
import aslogo from './canverse-morrow.png';
import * as stores from './stores';
import { StartGame } from './screens/Lobby';
import { CreateGame } from './screens/CreateGame';
import { Waiting } from './screens/Waiting';
import { Overview } from './screens/Overview';
import { EndScreen } from './screens/EndScreen';
import ErrorBoundary from 'react-error-boundary';
import { Row } from './components/Boxes';

const Routing = observer(() => {
  const playStore = useStore('playStore');
  const { isPlaying, gameData, isVoteRound, hasSubmitted, isResults } = playStore;
  if (isPlaying && hasSubmitted) return <Waiting />;
  if (isPlaying && isResults) return <EndScreen />;
  if (isPlaying && !isVoteRound) return <Play />;
  if (isPlaying && isVoteRound) return <Overview />;
  if (!gameData) return <CreateGame />;
  if (gameData && !isPlaying) return <StartGame />;
  return null;
});

const MyFallbackComponent = ({ componentStack, error }) => (
  <Row maxWidth={600}>
    <Typography variant="h3">Oops! An error occured!</Typography>
    <Typography variant="h5">
      Ask someone to push the 'Recover Broken Player' button at the bottom of the screen
    </Typography>
  </Row>
);

const App: React.FC<{}> = observer(() => {
  const classes = useStyles();
  const mobile: boolean = useMediaQuery('(max-width:600px)');
  const {
    playStore: { broadcastState },
  } = stores;
  return (
    <StoreProvider {...stores}>
      <ThemeProvider theme={LightTheme}>
        <CssBaseline />
        <div className={classes.root}>
          <AppBar position="fixed" color="default">
            <Toolbar disableGutters className={classes.toolbar} style={{ height: mobile ? 50 : 100 }}>
              <img src={logo} alt="Canverse" width={mobile ? 150 : undefined} style={{ maxWidth: '98%' }} />
            </Toolbar>
          </AppBar>
          <main className={classes.content} style={{ marginTop: mobile ? 50 : 100 }}>
            <div className={classes.visibleSpace}>
              <ErrorBoundary FallbackComponent={MyFallbackComponent}>
                <Routing />
              </ErrorBoundary>
            </div>
          </main>
          <Box padding={2} display="flex" alignItems="center" justifyContent="space-between">
            <Typography style={{ opacity: 0.6 }}>v0.7 beta</Typography>
            <Button variant="outlined" onClick={broadcastState}>
              Recover broken player
            </Button>
            <Link href="https://docs.google.com/forms/d/e/1FAIpQLSdR1ZolQX5dYqsaI-s3ZpqkiUr1oS_ZwVQ56fF02LYdXxTKgw/viewform?usp=sf_link">
              Seen a bug?
            </Link>
          </Box>
          <footer className={classes.footer} style={{ height: mobile ? 100 : 200 }}>
            <a href="https://themorrow.digital" target="_blank" rel="noopener noreferrer">
              <img src={aslogo} width={mobile ? 100 : 200} alt="Made with love by Morrow" />
            </a>
          </footer>
        </div>
      </ThemeProvider>
    </StoreProvider>
  );
});

const useStyles = makeStyles((theme: any) => ({
  root: {
    flexGrow: 1,
    height: '100vh',
    zIndex: 1,
    position: 'relative' as 'relative',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#FFFBFC',
    overflow: 'auto',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#FFFBFC',
    maxWidth: '100%',
  },
  content: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
    padding: '1rem',
    maxWidth: '100%',
    paddingTop: theme.custom?.contentPadding,
    paddingBottom: theme.custom?.contentPadding,
  },
  visibleSpace: {
    display: 'flex',
    flex: 1,
    width: '100%',
    justifyContent: 'space-around',
    flexDirection: 'row-reverse' as 'row-reverse',
  },
  footer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export default App;
