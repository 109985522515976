import React, { useRef, useState, FC, useEffect } from 'react';
import CanvasDraw from './react-canvas-draw';
import { CirclePicker } from 'react-color';
import { Button, Box, Grid, Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { useStore } from '../contexts/StoreContexts';
import UndoIcon from '@material-ui/icons/Undo';
import PublishIcon from '@material-ui/icons/Publish';
import DeleteIcon from '@material-ui/icons/Delete';
import { useWindowResize } from 'beautiful-react-hooks';

interface IColorPickerProps {
  setColor: (color: string) => void;
  color: string;
}

const ColorPicker: FC<IColorPickerProps> = observer(({ setColor, color }) => {
  const [currentColor, setCurrentColor] = useState(color);
  // const [] = React.useState<HTMLButtonElement | null>();

  return (
    <CirclePicker
      color={currentColor}
      onChange={({ hex }) => setCurrentColor(hex)}
      onChangeComplete={({ hex }) => setColor(hex)}
      colors={[
        '#000000',
        '#FFFFFF',
        '#FFA400',
        '#5B5F97',
        '#D1603D',
        '#8ABC2C',
        '#41939B',
        '#F1CE11',
        '#750D44',
        '#DE2824',
        '#FF007F',
        '#4CA7EE',
      ]}
    />
  );
});

const Dot = ({
  size,
  color,
  setBrushRadius,
  selected,
}: {
  selected: boolean;
  size: number;
  color: string;
  setBrushRadius: any;
}) => (
  <Box
    height={40}
    width={size * 3}
    minWidth={20}
    display="flex"
    justifyContent="center"
    alignItems="center"
    onClick={() => setBrushRadius(size)}
    style={{ cursor: 'pointer' }}
  >
    <Box
      style={{ transition: 'background-color 500ms', opacity: selected ? 1 : 0.6 }}
      bgcolor={color}
      borderRadius="50%"
      height={size * 2}
      width={size * 2}
    />
  </Box>
);

export interface ICanvasProps {
  existingDrawing?: string;
  compact?: boolean;
  dontRefresh?: boolean;
}

const Canvas: React.SFC<ICanvasProps> = observer(({ existingDrawing, compact }) => {
  let saveableCanvas: any = useRef();
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);
  const [color, setColor] = useState('black');
  const [brushRadius, setBrushRadius] = useState(3);
  const playStore = useStore('playStore');
  const { sendSubmission: sendLines } = playStore;

  useEffect(() => {
    if (existingDrawing) {
      // console.log(JSON.parse(existingDrawing));
      saveableCanvas?.current?.loadSaveData(existingDrawing);
    }
    if (!existingDrawing) saveableCanvas?.current?.clear();
    //eslint-disable-next-line
  }, []);

  useWindowResize(() => {
    setInnerWidth(window.innerWidth);
  });

  const width = compact ? 200 : Math.min(500, innerWidth - 20);

  return (
    <Box alignItems="center" display="flex" flexDirection="column">
      <CanvasDraw
        ref={saveableCanvas}
        brushColor={color}
        brushRadius={brushRadius}
        canvasWidth={width}
        canvasHeight={width}
        lazyRadius={0}
        hideGrid
        disabled={Boolean(existingDrawing)}
        loadTimeOffset={10}
        hideInterface
        style={{
          borderRadius: existingDrawing ? '8px' : 0,
          borderTopLeftRadius: '8px',
          borderTopRightRadius: '8px',
          borderColor: 'lightgrey',
          borderWidth: 8,
          borderStyle: 'solid',
        }}
      />
      {!existingDrawing && (
        <>
          <Grid
            container
            justify="space-evenly"
            alignItems="center"
            style={{
              width: `${width}px`,
              backgroundColor: 'lightgrey',
              padding: '10px',
              borderBottomLeftRadius: '8px',
              borderBottomRightRadius: '8px',
            }}
          >
            <Grid item>
              <ColorPicker {...{ color, setColor }} />
            </Grid>
            <Grid item>
              <Box display="flex" flexDirection="row" width={120}>
                <Dot setBrushRadius={setBrushRadius} selected={brushRadius === 3} size={3} color={color} />
                &nbsp; &nbsp;
                <Dot setBrushRadius={setBrushRadius} selected={brushRadius === 8} size={8} color={color} />
                &nbsp; &nbsp;
                <Dot setBrushRadius={setBrushRadius} selected={brushRadius === 16} size={16} color={color} />
              </Box>
            </Grid>
            <Grid item>
              <Box paddingTop={2}>
                <Button onClick={saveableCanvas?.current?.clear}>
                  clear &nbsp; <DeleteIcon />
                </Button>
                <Button onClick={saveableCanvas?.current?.undo}>
                  undo &nbsp; <UndoIcon />
                </Button>
              </Box>
            </Grid>
          </Grid>
          <Box padding={3} display="flex" flexDirection="row" justifyContent="center" alignItems="center">
            <Typography>Finished?</Typography>
            &nbsp; &nbsp;
            <Button
              color="primary"
              variant="contained"
              onClick={() => sendLines(saveableCanvas?.current?.getSaveData())}
            >
              submit &nbsp;
              <PublishIcon />
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
});

export default Canvas;
