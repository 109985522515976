import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Button, TextField, Box, InputAdornment, IconButton } from '@material-ui/core';
import Clear from '@material-ui/icons/Clear';
import { useStore } from '../contexts/StoreContexts';
import { Row, Column } from '../components/Boxes';

export const CreateGame = observer(() => {
  const playStore = useStore('playStore');
  const { myName, setMyName, createRoom, joinRoom } = playStore;
  const [roomName, setRoomName] = useState('');
  const [join, setJoin] = useState(false);
  return (
    <Column flex={0.5}>
      {!join && (
        <>
          <Box my={1} />
          <Button variant="outlined" onClick={() => setJoin(!join)}>
            Joining a game? &nbsp;
          </Button>
        </>
      )}
      {join && (
        <TextField
          fullWidth
          label="Enter Room"
          placeholder="Name required to start new game"
          onChange={({ target: { value } }) => setRoomName(value.toUpperCase())}
          value={roomName}
          autoFocus
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton color="secondary" onClick={() => setJoin(!join)}>
                  <Clear />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      )}
      <Box my={1} />
      <TextField
        fullWidth
        label="Enter your name"
        placeholder="Name required to start new game"
        onChange={setMyName}
        value={myName}
      />
      <Row my={1} />
      <Button
        disabled={!myName?.length}
        variant="contained"
        color="primary"
        onClick={() => (join ? joinRoom(roomName) : createRoom())}
      >
        {join ? 'Join' : 'create'} Game
      </Button>
    </Column>
  );
});
