import React, { useState } from 'react';
import Canvas from '../components/Canvas';
import { makeStyles } from '@material-ui/styles';
import { TextField } from '@material-ui/core';
import { useStore } from '../contexts/StoreContexts';
import { observer } from 'mobx-react-lite';
import { Row } from '../components/Boxes';
import { Button, Typography, Box } from '@material-ui/core';
import PublishIcon from '@material-ui/icons/Publish';
export interface IPlayProps {}

const TextAnswerBox = observer(() => {
  const playStore = useStore('playStore');

  const { sendSubmission: sendData } = playStore;
  const [answer, setAnswer] = useState('');
  return (
    <Box mt={2} width={1}>
      <TextField fullWidth label="Your answer" value={answer} onChange={({ target: { value } }) => setAnswer(value)} />
      <Row my={2}>
        <Typography>Finished?</Typography>
        &nbsp; &nbsp;
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            sendData(answer);
            setAnswer('');
          }}
        >
          submit &nbsp;
          <PublishIcon />
        </Button>
      </Row>
    </Box>
  );
});

const Play: React.SFC<IPlayProps> = observer(() => {
  const classes = useStyles();
  const playStore = useStore('playStore');
  const { getPreCursor } = playStore;
  const precursor = getPreCursor();
  if (!precursor) return null;
  const isTextRound = precursor.type === 'text';

  return (
    <div className={classes.root}>
      {isTextRound && (
        <div className={classes.prompt}>
          draw: <span className={classes.promptText}>{precursor.data}</span>
        </div>
      )}
      <Canvas key={isTextRound.toString()} existingDrawing={isTextRound ? undefined : precursor.data} />
      {!isTextRound && <TextAnswerBox />}
    </div>
  );
});

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: '100%',
  },
  prompt: {
    padding: '10px',
    fontSize: '1.25rem',
  },
  promptText: {
    fontSize: '1.75rem',
  },
}));

export default Play;
