import React from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Typography } from '@material-ui/core';
import { useStore } from '../contexts/StoreContexts';
import { Row, Column } from '../components/Boxes';
import { Player } from '../components/Player';

export const StartGame = observer(() => {
  const playStore = useStore('playStore');
  const { nextStage, gameData, leader } = playStore;
  return (
    <Column>
      <Typography variant="h4">Room: {gameData.room}</Typography>
      <Column alignItems="flex-start">
        <Typography variant="h6">Players: </Typography>
        {gameData?.players?.map(Player)}
      </Column>
      <Row>
        {leader ? (
          <Button variant="contained" color="primary" onClick={nextStage}>
            Start Game
          </Button>
        ) : (
          <>Waiting for host to start</>
        )}
      </Row>
    </Column>
  );
});
