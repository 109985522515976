import { computed, observable, action } from 'mobx';
import { IgameData as IGameData, ISubmission, IVote } from '../interfaces';
import { getPrompt } from '../stores/prompts';
import {
  createGame,
  getSupabaseClient,
  insertSubmission,
  joinGame,
  subscribeToGameUpdates,
  updateGameData,
} from '../functions/supabaseClient';
import * as randomstring from 'randomstring';

export default class playStore {
  private subscriptions: any[];
  @observable leader: boolean = true;
  @observable gameData: IGameData;

  @observable myName: string = localStorage.getItem('myName') || '';
  @observable playerPosition!: number;
  private playerId = Math.random().toString();

  private submissionEventQueue: ISubmission[] = [];
  private inProcess: boolean = false;

  @computed get hasSubmitted() {
    console.log('Has submitted did run k');
    console.log('Submitted players', this.submittedPlayers);
    return this.submittedPlayers?.find(({ id }) => id === this.playerId);
  }

  @computed get submittedPlayers() {
    console.log('Get submitted players did a run k');
    if (!this.gameData) {
      return null;
    }
    console.log(this.gameData);
    const { players, position } = this.gameData;
    const submittedPlayers = players
      .filter(({ stories }) => stories[position])
      .map(({ stories }) => ({ name: stories[position].name, id: stories[position].id }));
    return submittedPlayers;
  }

  @computed get unSubmittedPlayers() {
    return this.gameData?.players.filter(({ id }) => !this.submittedPlayers?.find(({ id: id2 }) => id === id2));
  }

  @computed get isPlaying() {
    return this.gameData && this.gameData.position > 0;
  }

  @computed get isVoteRound() {
    return (
      this.gameData &&
      this.gameData.position > this.gameData.players.length &&
      this.gameData.position <= this.gameData.players.length + this.gameData.players.length
    );
  }

  @computed get isResults() {
    return this.gameData && this.gameData.position === this.gameData.players.length + this.gameData.players.length + 1;
  }

  @computed get noTypenamegameData() {
    const { room, position, players } = this.gameData;
    const notypenameplayers = players.map((player) => {
      const { story, __typename, ...rest } = player as any;
      const notypenamestory = story.map((s) => {
        const { __typename, ...remaining } = s;
        return remaining;
      });
      return { story: notypenamestory, ...rest };
    });
    return { room, position, players: notypenameplayers };
  }

  @action restart = () => (this.gameData = null);

  omitTypename = (key: any, value: any) => (key === '__typename' ? undefined : value);

  getPreCursor = () => {
    console.log('Player count', this.gameData.players.length);
    console.log('Current player position', this.playerPosition);
    console.log('Game position', this.gameData.position);

    if (!this.gameData || !this.gameData.players) return;
    const stories =
      this.gameData?.players[(this.playerPosition + this.gameData.position - 1) % this.gameData.players.length]
        ?.stories;
    if (!stories || stories.length < this.gameData.position - 1) return;
    const story = stories[this.gameData.position - 1];
    if (!story) return null;
    return story;
  };

  @action setMyName = async ({ target: { value } }: any) => {
    this.myName = value;
    localStorage.setItem('myName', value);
  };

  @action createRoom = async () => {
    this.gameData = {
      room: randomstring.generate({ length: 4, capitalization: 'uppercase' }),
      position: 0,
      players: [
        {
          id: this.playerId,
          name: this.myName,
          stories: [],
          userWhoVotedOnStory: [],
        },
      ],
      submissions: [],
    };

    subscribeToGameUpdates(this.gameData.room, this.onGameDataUpdate);

    // Subscribe to submission updates
    getSupabaseClient()
      .from(`submissions`)
      .on('INSERT', (payload) => {
        console.log('Got new submission for room', payload.new);
      })
      .subscribe();

    this.leader = true;
    createGame(this.gameData.room, this.gameData);
  };

  @action onGameDataUpdate = (gameData: IGameData) => {
    this.gameData = gameData;
    if (this.gameData.position === 0) {
      this.playerPosition = this.gameData.players.findIndex(({ id }) => id === this.playerId);
    }
  };

  @action joinRoom = async (room: string) => {
    this.gameData = { position: 0, room, players: [], submissions: [] };
    this.leader = false;

    // TODO: Don't allow join if game is full (8 players)

    const data = await joinGame(room);
    subscribeToGameUpdates(room, this.onGameDataUpdate);
    // Subscribe to submission updates
    getSupabaseClient()
      .from('submissions')
      .on('INSERT', (payload) => {
        console.log('Got new submission for room', payload.new);
      })
      .subscribe();
    console.log('Data received', data);

    if (data.gameData) {
      this.gameData = data.gameData as IGameData;
      this.gameData.players.push({ id: this.playerId, name: this.myName, stories: [], userWhoVotedOnStory: [] });
      updateGameData(this.gameData);
    }
  };

  @action nextStage = async () => {
    this.gameData.position++;
    console.log('Next stage was run');
    if (this.gameData.position === 1) {
      this.gameData.players.forEach((player) => {
        player.stories.push({ data: getPrompt(), vote: 0, type: 'text', id: 'server', name: 'server' });
      });
    }

    updateGameData(this.gameData);
  };

  @action sendSubmission = async (data: string) => {
    insertSubmission({
      playerId: this.playerId,
      playerName: this.myName,
      data,
      round: this.gameData.position,
      room: this.gameData.room,
    });
  };

  @action vote = async (data: Omit<IVote, 'voteCaster'>) => {
    // NOT SURE WHAT SHOULD HAPPEN HERE?
    // await client.mutate({
    //   mutation: vote,
    //   variables: { input: { ...data, voteCaster: this.playerId } },
    // });
  };

  public receiveSubmissionEvent = (event: ISubmission) => {
    console.log('Receive submission called');
    if (!this.inProcess) {
      this.processEventsSynchronously(event);
    } else {
      console.log('queuing event');
      this.submissionEventQueue.push(event);
    }
  };
  public processEventsSynchronously = async (event: ISubmission) => {
    this.inProcess = true;
    await this.handleSubmission(event);
    this.inProcess = false;
    if (this.submissionEventQueue.length) {
      this.processEventsSynchronously(this.submissionEventQueue.shift());
    }
  };

  @action handleSubmission = async (state: ISubmission) => {
    console.log('Handling submission');
    console.log(state);
    if (!this.leader) {
      return;
    }
    console.log('Handle submission was run');
    this.gameData.players[state.playerId].story[state.round] = {
      type: (state.round - 1) % 2 === 0 ? 'drawing' : 'text',
      vote: 0,
      data: state.data,
      name: state.playerName,
      id: state.playerId,
    };
    if (this.gameData.players.filter((player) => player.stories[state.round]).length === this.gameData.players.length) {
      this.gameData.position++;
    }

    updateGameData(this.gameData);
  };

  @action handleVote = async (state: IVote) => {
    if (!this.leader) {
      return;
    }
    const player = this.gameData.players[state.player];
    if (player.userWhoVotedOnStory.includes(state.voteCaster)) {
      return;
    }
    player.userWhoVotedOnStory.push(state.voteCaster);
    player.stories[state.storyBoardPosition].vote++;
    if (
      state.player === this.gameData.position - 1 - this.gameData.players.length &&
      player.userWhoVotedOnStory.length === this.gameData.players.length
    ) {
      this.gameData.position++;
    }

    updateGameData(this.gameData);
  };

  private endGame = () => this.subscriptions.map((sub) => sub.unsubscribe());

  public broadcastState = async () => {
    // DO WE STILL NEED THIS?
    // await client.mutate({
    //   mutation: nextStage,
    //   variables: { input: this.noTypenamegameData },
    // });
  };
}
