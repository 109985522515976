import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Typography, Box, makeStyles, useMediaQuery, Paper, Badge } from '@material-ui/core';
import { useStore } from '../contexts/StoreContexts';
import { Column } from '../components/Boxes';
import { Player } from '../components/Player';
import Canvas from '../components/Canvas';
import ArrowForward from '@material-ui/icons/ArrowForward';
import ArrowDown from '@material-ui/icons/ArrowDownward';

export const Overview = observer(() => {
  const classes = useStyles();
  const [displayIndex, setDisplayIndex] = useState(0);
  const [currentPlayer, setCurrentPlayer] = useState(0);
  const playStore = useStore('playStore');
  const { gameData, vote } = playStore;
  const playerNumber = gameData.position - 1 - gameData.players.length;
  const { name, stories } = gameData.players[playerNumber];
  const mobile: boolean = useMediaQuery('(max-width:600px)');

  useEffect(() => {
    if (currentPlayer !== playerNumber) {
      setCurrentPlayer(playerNumber);
      setDisplayIndex(0);
    }
  }, [currentPlayer, playerNumber]);

  setTimeout(() => {
    const shouldIncrement = displayIndex < gameData.players.length;
    return shouldIncrement ? setDisplayIndex(displayIndex + 1) : null;
  }, 3000);

  return (
    <Column maxWidth="100%">
      <Typography gutterBottom variant="h4">
        Story for: {name}
      </Typography>
      <Typography gutterBottom variant="h6">
        Vote for your favourite by clicking
      </Typography>
      <Box flexDirection={mobile ? 'column' : 'row'} display="flex" overflow="auto" maxWidth="100%">
        {stories.map(({ id, name, type, data, vote: score }, index) => (
          <React.Fragment key={id}>
            <Column height={1}>
              <Paper
                elevation={5}
                key={index}
                style={{
                  height: '100%',
                  cursor: 'pointer',
                  flexDirection: 'column',
                  display: 'flex',
                  padding: '1em',
                  margin: '1em',
                  paddingTop: '0.5em',
                  position: 'relative',
                }}
                onClick={() => vote({ room: gameData.room, player: playerNumber, storyBoardPosition: index })}
              >
                <Badge
                  color="secondary"
                  badgeContent={score}
                  showZero
                  style={{ position: 'absolute', left: 0, top: 0 }}
                />
                <Box alignSelf="baseline">
                  <Player name={name} key={id} />
                </Box>
                <Box
                  display="flex"
                  flex={1}
                  alignItems="center"
                  minHeight={200}
                  width={type === 'drawing' ? 200 : undefined}
                  className={`${displayIndex >= index && classes.in} ${classes.box}`}
                >
                  {displayIndex >= index &&
                    (type === 'text' ? (
                      <Column flex={1}>
                        <Typography>{data}</Typography>
                      </Column>
                    ) : (
                      <Canvas dontRefresh={displayIndex > index} compact existingDrawing={data} />
                    ))}
                </Box>
              </Paper>
            </Column>
            {index < stories.length - 1 && <Column m={2}>{mobile ? <ArrowDown /> : <ArrowForward />}</Column>}
          </React.Fragment>
        ))}
      </Box>
    </Column>
  );
});

const useStyles = makeStyles(() => ({
  in: {
    opacity: '1 !important',
  },
  box: {
    transition: 'opacity 500ms',
    opacity: 0,
  },
}));

// export const Overview = observer(() => {
//   const playStore = useStore('playStore');
//   const { gameData } = playStore;
//   // {gameData.players[gameData.position-1-gamesta]}
//   return (
//     <Column>
//       <Typography variant="h5">Waiting for all players to submit</Typography>

//       {gameData.players.map(({ name, id, story }) => {
//         return (
//           <Column>
//             <Typography>{name}</Typography>
//             {story.map(({ id, name, type, data }) => (
//               <Row>
//                 <Player name={name} key={id} />
//                 {type === 'text' ? <Typography>{data}</Typography> : <Canvas existingDrawing={JSON.parse(data)} />}
//               </Row>
//             ))}
//           </Column>
//         );
//       })}
//     </Column>
//   );
// });
