import { Typography, Box, Button } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Column, Row } from '../components/Boxes';
import { useStore } from '../contexts/StoreContexts';
import { Player } from '../components/Player';
import { IStory } from '../interfaces';
import Canvas from '../components/Canvas';

export const EndScreen = observer(() => {
  const playStore = useStore('playStore');
  const { gameData, restart } = playStore;
  const userMap = {} as { [key: string]: { name: string; votes: number } };
  let topBoard: IStory = null;

  // this is wank but i can't think right now
  gameData.players.forEach((player) => {
    player.stories.forEach((storyboard) => {
      if (!userMap[storyboard.id]) {
        userMap[storyboard.id] = { name: storyboard.name, votes: 0 };
      }
      userMap[storyboard.id].votes += storyboard.vote;
      if (!topBoard || storyboard.vote > topBoard.vote) {
        topBoard = storyboard;
      }
    });
  });

  const sortedVotes = Object.values(userMap).sort(({ votes: a }, { votes: b }) => b - a);
  const winner = sortedVotes.shift();
  return (
    <Row flexWrap="wrap" justifyContent="space-around" width={1}>
      <Column mb={3} minWidth={400}>
        <Typography gutterBottom variant="h4">
          Winner:
        </Typography>
        <Box display="flex" width={1} mb={4}>
          <Column mr={4}>
            <Typography variant="h5">{winner?.votes} </Typography>
          </Column>
          <Player name={winner?.name || ''} key={winner?.name} />
        </Box>
        <Typography gutterBottom variant="h5">
          Other players:
        </Typography>
        {sortedVotes.map(({ name, votes }, index) => (
          <Box display="flex" width={1} key={index}>
            <Column mr={4}>
              <Typography variant="h5">{votes} </Typography>
            </Column>
            <Player name={name} key={index} />
          </Box>
        ))}
      </Column>
      <Column>
        <Typography gutterBottom variant="h4">
          Top board:
        </Typography>
        {topBoard &&
          (topBoard.type === 'text' ? (
            <>
              <Typography variant="h6">“{topBoard.data}”</Typography>
              <Typography>by: {topBoard.name}</Typography>
            </>
          ) : (
            <>
              <Canvas existingDrawing={topBoard.data} />
              <Typography>by: {topBoard.name}</Typography>
            </>
          ))}
        <Box mb={4} />
        <Button color="primary" variant="contained" onClick={restart}>
          Play again
        </Button>
      </Column>
    </Row>
  );
});
